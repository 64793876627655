p {
  font-family: "Monaco";
  color: rgba(0, 0, 0, 0.683);
  font-size: 1rem;
}

button {
  background: none;
  border: none;
  font-family: "Monaco";
  color: rgba(0, 0, 0, 0.683);
}

button:hover {
  transition: 0.25s ease;
  text-shadow: 0px 0px 10px #000000b9;
}

.background {
  position: fixed;
  inset: 0px;
  background-color: gray;
  /* mix-blend-mode: difference; */
  opacity: 80%;
  transition: all 0.3s ease;
}

.modalWindow {
  position: fixed;
  inset: 20px;
  width: 50%;
  height: fit-content;
  bottom: 30%;
  padding: 10px;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.699);
  /* opacity: 80%; */
}

.three {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.four {
  display: flex;
  justify-content: flex-end;
}

.privacyPolicy {
  display: flex;
  justify-content: space-between;
}
