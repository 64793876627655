.iframeControls {
  margin: 0 auto;
  text-align: center;
}

.control {
  display: inline-block;
  border: none;
  background-color: transparent;
  font-family: "Monaco";
  color: rgba(0, 0, 0, 0.683);
  margin: 50px, 0;
  transition: 0.25s ease;
}

.control:hover {
  transition: 0.25s ease;
  text-shadow: 0px 0px 10px #000000b9;
}

.control:active {
  color: rgba(255, 183, 0, 0.683);
}
