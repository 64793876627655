.active {
  background-color: rgba(255, 228, 75, 0.318) !important;
}

.tableWrapper {
  overflow-x: hidden;
  /* height: calc(100vh - 62.5px); */
  height: max-content;
  width: max-content;
}

table,
tbody,
tr,
th,
td {
  user-select: none;
}

table {
  background-color: none;
  font-family: "Monaco";
  font-size: 0.75rem;
  color: rgb(255, 255, 255);
  margin-left: 20px;
  border-collapse: collapse;
}

th,
td {
  padding-top: 0px;
  padding-left: 5px;
  padding-right: 15px;
  padding-bottom: 0px;
}
td {
  border-left: 1px solid rgb(0, 0, 0);
  border-right: 1px solid rgb(0, 0, 0);
}

thead {
  text-align: left;
  padding-bottom: 10px;
}

tr:hover:not(:first-child) {
  background-color: rgba(0, 0, 0, 0.13) !important;
  transition: 0.15s ease;
  cursor: pointer;
}

tr:active:not(:first-child) {
  background-color: rgba(0, 0, 0, 0.318) !important;
  transition: 0.15s ease;
}

.highlight {
  background-color: blue;
}

table {
  text-align: left;
  position: relative;
  border-collapse: collapse;
}
th,
td {
  padding: 0.25rem;
}

tr.red th {
  background: rgb(200, 167, 0);
  color: white;
}

th {
  /* background: white; */
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
