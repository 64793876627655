body {
  overflow: scroll;
}

.mainHeader {
  text-align: center;
  color: rgba(0, 0, 0, 0);
  font-family: "Monaco";
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(0, 0, 0);
  font-size: 2rem;
  margin: 0;
}

.header {
  grid-column: 1 / 3;
  grid-row: 1;
}

.left {
  grid-column: 1;
  grid-row: 2;
  width: fit-content;
}

.right {
  grid-column: 2;
  grid-row: 2;
}

.navWrapper {
  margin: 0 auto;
  text-align: center;
  width: max-content;
}

.nav {
  display: inline-block;
  border: none;
  background-color: transparent;
  font-family: "Monaco";
  color: rgba(0, 0, 0, 0.683);
  margin: 50px, 0;
  transition: 0.25s ease;
}

.nav:hover {
  transition: 0.25s ease;
  text-shadow: 0px 0px 10px #ffffffb9;
}

.navbar {
  width: 20%;
}

.navbar:hover > .navbarDropdown {
  max-height: 500px;
  overflow: auto;
  width: 800px;
}
.navbarHeader {
  width: max-content;
  height: max-content;
  z-index: 1000;
}

.navbarDropdown {
  display: grid;
  position: absolute;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #7f7f7fc7;
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.3s ease;
  z-index: 1000;
  /* width: fit-content; */
  /* mix-blend-mode: difference; */
}

.tableIcon {
  display: inline;
  width: 30px;
  height: auto;
  margin-left: 30px;
  /* position: absolute; */
}

.glow {
  animation-name: button;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  color: #ffffff00;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(235, 235, 235);
  transition: 0.25s ease;
}

@keyframes button {
  0% {
    opacity: 5%;
  }
  40% {
    opacity: 100%;
  }
  100% {
    opacity: 5%;
  }
}
