/* body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-image: linear-gradient(315deg, #3f3813 0%, rgb(73, 73, 73) 74%);
  margin: 0;
  border: solid green;
  background-attachment: fixed;
} */

.splash {
  height: 100vh;
  width: 100vw;
  background-color: rgb(160, 160, 160);
  /* background-image: linear-gradient(315deg, #3f3813 0%, rgb(73, 73, 73) 74%); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.splash > * {
  color: #5920d6;
  color: black;
  mix-blend-mode: luminosity;
}

.splashHeader {
  position: absolute;
  text-align: center;
  color: rgba(255, 255, 255, 0);
  font-family: "Monaco";
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: rgba(148, 148, 148, 0);
  font-size: 10vw;
  /* text-shadow: 1px 4px 40px #c1c1c1a5, 1px 4px 4px #4e4e4e; */
  text-shadow: -2px 2px 4px rgba(75, 75, 75, 0.5),
    2px -2px 0px rgba(197, 197, 197, 0.9);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  user-select: none;
  /* mix-blend-mode: normal; */
}

.textAnimationContainer {
  /* border: solid red; */
  width: 110vw;
  height: 100vh;
  overflow: hidden;
  margin-left: -5%;
  position: absolute;
  /* border: solid red; */
}

.textAnimation {
  font-size: 25vw;
  margin: 0;
  line-height: 50%;
  overflow-wrap: break-word;
  font-kerning: auto;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  text-align: center;
  transition: ease 0.25s;
  user-select: none;
  /* text-shadow: -2px 2px 4px rgba(0, 0, 0, 0.5),
    2px -2px 4px rgba(216, 216, 216, 0.9); */
  -webkit-background-clip: text;
  -moz-background-clip: text;
}

.secondary {
  color: rgba(255, 255, 255, 0);
  /* -webkit-text-stroke-color: blue; */

  /* text-shadow: -2px 2px 4px rgba(0, 0, 0, 0.5),
    2px -2px 0 rgba(218, 218, 218, 0.9); */
  margin-left: -3%;
}

.tertiary {
  /* -webkit-text-stroke-color: red; */
}

.navButton {
  z-index: 500 !important;
  position: absolute;
  animation-name: button;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  border: none;
  background-color: transparent;
  font-family: "Monaco";
  font-size: 1.5vw;
  text-align: center;
  margin-left: -2.8%;
  margin-top: -1.5%;
  color: #ffffff00;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(235, 235, 235);
  transition: 0.25s ease;
}

.navButton:hover {
  transition: 0.25s ease;
  text-shadow: 0px 0px 10px #ffffff;
}
.navButton:active {
  /* color: red; */
}

@keyframes button {
  0% {
    opacity: 5%;
  }
  40% {
    opacity: 100%;
  }
  100% {
    opacity: 5%;
  }
}
