.mainHeader {
  text-align: center;
  color: rgba(0, 0, 0, 0);
  font-family: "Monaco";
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(0, 0, 0);
  font-size: 2rem;
  margin: 0;
}

.main {
  height: 100%;
  width: 100%;
}

.navWrapper {
  margin: 0 auto;
  text-align: center;
}

.nav {
  display: inline-block;
  border: none;
  background-color: transparent;
  font-family: "Monaco";
  color: rgba(0, 0, 0, 0.683);
  margin: 50px, 0;
  transition: 0.25s ease;
}

.nav:hover {
  transition: 0.25s ease;
  text-shadow: 0px 0px 10px #000000b9;
}
