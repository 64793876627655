.infoContainer {
  margin-left: 10%;
}

.link {
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-family: "Monaco";
  font-size: 0.6rem;
}

.text {
  color: rgb(0, 0, 0);
  font-family: "Monaco";
  font-size: 0.6rem;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  transition: 0.25s ease;
  text-shadow: 0px 0px 10px #000000b9;
}

a:active {
  text-decoration: none;
  color: rgba(255, 183, 0, 0.683);
}
