body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: rgb(160, 160, 160);

  /* background-image: linear-gradient(315deg, #3f3813 0%, rgb(73, 73, 73) 74%); */
  margin: 0;
  background-attachment: fixed;
}

.app {
  height: 100vh;
  width: 100vw;
}
