.sortingButtons {
  background: none;
  border: none;
  color: white;
  padding: 0;
  display: block;
  /* float: right; */
}

.sortingButtons:active {
  color: rgba(255, 228, 75, 0.318);
}

.sortingButtonsWrapper {
  display: inline-block;
  width: fit-content;
  /* float: right; */
}
